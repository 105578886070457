

@media (max-width: 1400px) {

.sidebar {
    min-width: 230px;
    max-width: 230px;
}
.btn {
	padding: .65rem 1.75rem;
}
.alert-success {
    font-size: 1.125rem;
}
.js-responsive-table th, .fold-table th {
	font-size:.90rem;
}
.my-account-container{
	padding:0px 80px 80px 80px;
}
.login-left-content, .login-right{
	padding:70px;
}
.right-sidebar{
    flex: 0 0 350px;
    width: 350px;
	padding:1.875rem;
}
.right-sidebar .lead{
	font-size:1rem;
}
.left-sidebar .renew-product-right ul li label{
	font-size:.875rem;
}
.left-sidebar .renew-product-right ul li span {
    font-size: 1.125rem;
}
}
 @media only screen and (min-device-width: 768px) and (max-device-width: 1300px) and (orientation:portrait) {
.renew-product-right ul li{
	display:block !important;
}
.renew-product-right ul li span{
	display:block !important;
}    
	
 }

@media (max-width: 1300px) {
.js-responsive-table, .js-responsive-table .btn{
	font-size:.875rem;
}
}


@media (max-width: 1200px) {
.sidebar {
    min-width: 200px;
    max-width: 200px;
}
.login-social li a{
	font-size:.875rem;
}
.payment-section{
    display: block;
}
.right-sidebar{
	display:block;
	width:100%;
}
left-sidebar .card-body .d-flex{
	display:block !important;
	margin-bottom:20px !important;
}
.login-social li{
	display:inline-block;
	width:auto;
	float:none;
	padding: 0 2px;
	margin-bottom:10px;
}
.login-social {
    margin: 0 -2px;
}
.login-left-content, .login-right{
	padding:70px 50px;
}
.featured-product-box h3 {
    font-size: 1.375rem;
    margin-bottom: 20px;
}
.featured-product-box label {
    font-size: 1rem;
}
.renew-product-box .card-title, .renew-product-address, .renew-product-right ul li span {
    font-size: 1.25rem;
}
.renew-product-right ul li label{
	font-size:.875rem;
}
.renew-product-left{
	padding:0 20px;
}
.renew-product-center {
    margin-right: 20px;
    padding-right: 20px;
}
.card-body, .tab-content {
    padding: 1.125rem;
}
.sidebar-nav li a{
	font-size:.875rem;
	padding: 8px 15px;
}
.sidebar h5{
	padding-top:20px;
}
.sidebar-nav{
	margin:20px 0;
}
.expires-badge{
	margin-right:-19px;
}
.plan-current{
	font-size:.775rem;
}
.account-noti-box{
	padding: 1.125rem;
	margin-bottom:20px;
}
.noti-switch li{
	padding:0 20px;
}
.contact-renew img {
    margin-right: 20px;
}
.phone-no-renew{
	padding:0 20px;
}
.bar {
    width: 150px;
    height: 150px;
}
.est-remaning {
    font-size: 1rem;
}
}

@media (min-width:768px) and (max-width:1024px) {
.header-nav li a span.header-nav-text{
	display:none;
}
}

@media (max-width: 992px) {
.js-responsive-table table, .js-responsive-table tbody, .js-responsive-table table tr, .js-responsive-table table td, .js-responsive-table table th {
	display: block;
	width: 100%;
}
.featured-title {
    font-size: 1rem;
	margin-bottom:20px;
	margin-left:-15px;
}
.featured-product-box-plan, .featured-product-box-price, .featured-product-box-term, .featured-product-box-signup {
    padding: 15px;
}
.featured-title::after {
    margin-right: -0.85em;
}
.js-responsive-table, .js-responsive-table .btn{
	font-size:1rem;
}
.fold-table th, .fold-table td{
	font-size:.875rem;
}
.fold-table th {
    padding: 12px;
}
.js-responsive-table table tr {
	max-height: 60px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
.js-responsive-table th{
    padding: 15px;
}
.js-responsive-table .icon-accordion {
	display: block;
	text-align: center;
	width: 20px;
	background:#3f4759;
	height: 20px;
	color:#fff;
	border-radius:50%;
	position: absolute;
	font-weight:400;
	right: 15px;
	top: 20px;
	line-height:16px;
	font-style: normal;
	font-size: 1.25rem;
}
.js-responsive-table td h3 {
	display: block;
}
.js-responsive-table table tr td:first-child em{
	margin-top:6px;
	display:block;
}
.accordion-opened{
	background:#f1f3f4;
}
.gray-table-dt-value, .gray-table-dt-label{
	padding:10px 12px;
}
.phone-no-renew{
	font-size:2rem;
	padding:20px 0;
}
.contact-renew{
	display:block;
}
.autopay-flex-box{
	display:block;
}
.autopay-flex-box-right{
	margin-top:20px;
}
}


@media (max-width: 768px) {
.sidebar {
	min-width: 220px;
	max-width: 220px;
	margin-left: -220px !important;
}
.sidebar.active {
	min-width: 220px;
	max-width: 220px;
	text-align:left;
	margin-left: 0px !important;
}
.header-nav li a span.header-nav-text{
	display:none;
}
.header-nav li a .fa {
    margin-right: 0px;
}
.top-header{
	padding-top:8px;
}
.logo-mobile{
	display:inline-block;
	margin-top:10px;
	margin-bottom:10px;
}
.logo-mobile img{
	width:auto;
	height:50px;
}
.header-nav{
	float:right;
	margin-top:13px;
}
.header-nav li a {
    padding: 10px 12px;
}
.logo{
	display:none;
}
.sidebar.active .sidebar-nav li a span.nav-text {
    display: inline-block;
}
.sidebar.active h5{
	padding: 15px 15px 5px 15px;
}
.sidebar.active .sidebar-nav{
	margin:10px 0;
}
.sidebar-nav li a{
	font-size:.875rem;
}
.sidebar.active .sidebar-nav li a, .sidebar-nav li a {
    padding: 4px 5px;
}
.header-nav li a{
	border-left:none;
}
.featured-product-box{
	display:block !important;
}
.featured-product-box-price, .featured-product-box-term{
	width:50%;
	float:left;
	margin-bottom:20px;
}
.featured-product-box-term{
	border-right:none;
}
.featured-product-box-signup{
	text-align:center;
	margin-bottom:10px;
}
.featured-product-box-signup label{
	display:block;
}
.login-box{
	display:block;
}
.login-left {
    width: 100%;
}
.my-account-container {
    padding: 0px 25px 25px 25px;
}
.my-account-md-content {
    padding: 60px;
    background: #fff;
}
.pay-card{
	display:block;
}
.pay-card ul{
	padding:0;
	margin:10px 0 0 0;
}
.pay-card ul li{
	display:block;
}
.pay-card ul li span{
	display:block;
}
.accordian-card a{
	font-size:1rem;
}
.gray-table-dt-label, .gray-table-dt-value{
	width:50%;
	float:left;
}
.gray-table{
	clear:both;
	display:block;
	font-size:.875rem;
}
.gray-table-dt-value{
	border-bottom: 1px solid #d8d9da;
}
.gray-table-dt-label{
	border-bottom:1px solid #7f898f;
}
.pay-echeck{
	display:block;
}
.pay-echeck img{
	margin-bottom:15px;
}
.page-title{
	line-height:1.1;
}
.gray-box, .right-sidebar{
	padding:1.25rem;
}
.lead{
	font-size:1rem;
}
.accordian-card{
	display:flex;
}
.accordian-card .exp-date{
	margin-left:25px;
}
.recomended-box{
	display:block;
}
.or-txt{
	padding:5px 15px 15px 15px;
}
.tab-content {
    padding: 10px;
}
}

@media (max-width: 767px) {
.expires-right {
    width: auto;
    display: inline-block;
    float: right;
}
.renew-product-left{
	display:block !important;
	padding:15px 0;
}
.renew-product-box{
	display:block !important;
}
.renew-product-box .card-title, .renew-product-address, .renew-product-right ul li span {
    font-size: 1.125rem;
}
.renew-product-center{
	margin:15px 0;
	padding:0;
	border:none;
}
.diverter{
	margin-bottom:40px;
}
.my-account-md-content p{
	font-size:1rem;
}
.account-noti-box{
	display:block;
}
.noti-switch {
    margin: 25px 0 0 0px;
    padding: 0;
    display: block;
}
.noti-switch li{
	padding:0 10px;
}
.noti-switch li:first-child{
	padding-left:0px;
	border-left:none;
}
}

@media (max-width: 575px) {
.plans-list li{
	display:block;
}
.plans-txt{
	margin:6px 0;
}
.plans-list li .btn{
	margin-top:15px;
}
.super-sever-flex-box{
	display:block;
}
.super-sever-flex-box img{
	margin-bottom:20px;
}
.p-main{
	padding:1.25rem;
}
.fold-table th, .fold-table td{
	font-size:.7rem;
}
.fold-table th {
    padding: 4px;
}
table.fold-table > tbody > tr.view td:first-child {
    padding-left: 25px;
}
table.fold-table > tbody > tr.view td:first-child::before, table.fold-table > tbody > tr.view th:first-child::before {
    left:5px;
	font-size:.875rem;
}
.fold-table-box{
	margin-left:-20px;
	margin-right:-20px;
}
.login-left-content, .login-right, .my-account-md-content {
    padding: 40px 25px;
}
.diverter {
    margin-bottom: 30px;
    margin-top: -40px;
}
.noti-switch li img{
	width:30px;
	height:auto;
}
.switch {
    width: 50px;
    height: 24px;
}
.slider::before {
    height: 16px;
    width: 16px;
}
.autopay-link{
	font-size:.9rem;
}
.login-social li {
    width: 100%;
}
.quickpay-box {
    display: block;
}
.btn-group-lg > .btn, .btn-lg {
    font-size: 1rem;
}
.alert .d-flex{
	display:block !important;
}
.alert{
	display:block !important;
}
.nav-tabs li, .nav-tabs li a{
	display:block;
	width:100%;
}
.gray-table-dt-label{
	font-size:.75rem;
}
.modal-body {
    padding: 20px;
}
.modal-header{
	padding:1rem 1rem;
}
}

/**** Styles taken from enrollment app ****/
@media (max-width:992px) {
	.columns {
		width: 50%;
	}

	.min-h-price {
		min-height: 140px;
		padding-right: 5px;
		padding-left: 5px;
	}

	.price .header {
		font-size: 18px;
		min-height: 80px;
	}

	.border-none {
		font-size: 14px;
	}

	.navbar-nav li:last-child {
		padding-right: 15px;
	}

	.footer-green, .footer-gray {
		min-height: 311px;
	}

	.footer-bot-ul-menu li a {
		font-size: 15px;
	}

	.thankyou-banner h1 {
		font-size: 100px;
	}

	.thankyou-banner p {
		font-size: 34px;
	}

	.scroll-div {
		position: inherit;
		top: inherit !important;
		margin-top: 30px;
	}

	.navbar-header {
		float: left;
	}

	.padd-lr-50 {
		padding: 0 0px;
	}

	.nav-tabs > li > a {
		padding: 6px 3px;
		font-size: 10px;
	}

	.tab-items .nav-tabs {
		margin-right: -20px;
		margin-top: 30px;
	}

		.tab-items .nav-tabs > li:first-child a {
			margin-left: 0px;
		}
}


@media (max-width:767px) {
	.btn-default {
		margin-bottom: 15px;
	}

	.featured-products {
		padding: 15px 15px 0 15px;
	}

	.footer-bottom p {
		margin: 10px 0;
	}

	.columns {
		width: 100%;
		padding: 15px;
	}

	.min-h-price {
		min-height: inherit;
	}

	.price .header {
		font-size: 18px;
		min-height: inherit;
	}

	.btn-group-lg > .btn, .btn-lg {
		padding: 10px 20px;
		font-size: 15px;
	}

	.breadcrumb {
		float: left;
		margin-top: 20px;
	}

	.footer-green, .footer-gray {
		min-height: inherit;
	}

	.footer-bot-ul-menu li {
		padding: 0 10px;
		margin: 5px 0;
	}

	.thankyou-banner {
		margin: 0 0 20px 0;
	}

		.thankyou-banner h1 {
			font-size: 60px;
		}

		.thankyou-banner p {
			font-size: 20px;
			margin-bottom: 0;
		}

	.credit-card-form .form-control, .phone-number-input .form-control {
		float: none;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.credit-card-form, .phone-number-input {
		margin-left: 0px;
		margin-right: 0px;
	}

	.modal-header h3 {
		font-size: 22px;
	}

	.font-22 {
		font-size: 18px;
	}

	.btn-dark {
		margin-bottom: 10px;
	}

	.modal-body {
		padding: 15px;
	}

	.modal-header {
		padding: 5px 15px 10px 15px;
	}

	.form-group {
		margin-bottom: 15px;
	}



	.panel-title > a {
		font-size: 14px;
	}

	.more-less {
		line-height: 40px !important;
		font-size: 16px !important;
		margin-top: -11px;
		width: 40px;
	}

	.content-white-box {
		padding: 15px;
	}

	.page-top-sub {
		margin-top: 0px;
		padding: 15px 0;
		margin-bottom: 15px;
	}

	.wizard-ul li {
		font-size: 12px;
		letter-spacing: -.5px;
	}

	.wizard-ul:after {
		top: 36px;
	}

	.inner-title {
		padding: 15px 0;
	}

		.inner-title h1 {
			font-size: 24px;
		}

	.pytway {
		text-align: center;
	}

	.padd-lr-50 {
		max-width: 300px;
		margin: 0 auto;
	}

	.wizard-step li {
		width: 100%;
		margin-bottom: 30px;
	}

	.wizard-step:after {
		display: none;
	}

	.thk-topsec .lftside, .thk-topsec .rgtside {
		padding: 0 15px 0 15px;
	}

	.btn-download1 {
		max-width: 322px;
	}

	.english-letter {
		margin: auto;
	}

	.call-enroll {
		text-align: center;
	}

	.tab-items .nav-tabs {
		margin-right: 0px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.nav-tabs > li > a {
		padding: 8px 12px;
		font-size: 14px;
	}
}