html, body {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    color: #1e2930;
    font-weight: 500;
}

.table-alternating tr:nth-child(4n) {
    background: #CCC;
}

.table-alternating-2 tr:nth-child(even) {
    background: #e7e7e7;
}

.table-alternating-odd tr:nth-child(odd) {
    background: #E5E5E5;
}

.table-alternating-odd tr:nth-child(even) {
    background: #F3F3F3;
}

.table-alternating-odd {
    width: 100%;
}

.table-alternating-odd td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

p, td {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

.td-numeric {
    text-align: right !important;
    padding-right: 12% !important;
}

.td-border-right {
    border-right: 1px solid lightgray;
    text-align: center;
}

.td-border-left {
    border-left: 1px solid lightgray;
    text-align: center;
}

.td-padding-left {
    padding-left: 45px;
}

.barlow {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.top-header > .container-fluid {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.sidebar {
    min-width: 260px;
    max-width: 260px;
    color: #bec1c8;
    background: #445e7b;
}

    .sidebar.active {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
    }

        .sidebar.active .logo-lg, .sidebar .logo-sm {
            display: none;
        }

        .sidebar .logo-lg, .sidebar.active .logo-sm {
            display: inline-block;
        }

.logo {
    text-align: center;
    padding: 30px 0;
}

.content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    background: #ffffff;
}

.sidebar h5 {
    font-size: .875rem;
    text-transform: uppercase;
    padding: 0 25px;
    color: #ffffff;
    font-weight: 400;
    border-top: 1px solid #363b4c;
    padding-top: 30px;
}

.sidebar-nav {
    margin: 25px 0;
    padding: 0;
    list-style: none;
}

    .sidebar-nav li a {
        padding: 12px 17px;
        color: #ffffff;
        display: block;
    }

        .sidebar-nav li a:hover, .sidebar-nav li a:focus {
            color: #fff;
            text-decoration: none;
        }

        .sidebar-nav li a .fa {
            margin: 0 8px;
        }

    .sidebar-nav li.active a {
        color: #fff;
        background: #697e95;
    }


.sidebar-nav-header {
    margin: 25px 0;
    padding: 0;
    list-style: none;
    color: #80c765 !important
}

    .sidebar-nav-header li a {
        padding-left: 12px;
        padding-top: 0px;
        color: #80c765 !important;
        display: flex;
        font-weight: 500;
    }

    .sidebar-nav-header h5 {
        color: #80c765 !important;
        text-decoration: none;
        padding-left: 0px;
    }

    .sidebar-nav-header li a:hover, .sidebar-nav-header li a:focus {
        color: #80c765 !important;
        text-decoration: none;
    }

    .sidebar-nav-header li a .fa {
        margin: 0 15px;
        font-size: 18px;
        width: 23px;
    }

    .sidebar-nav-header li.active a:before {
        content: '';
        color: #80c765 !important;
        width: 8px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .sidebar-nav-header li.active a {
        color: #208e01 !important;
        position: relative;
    }

.sidebar-bottom h5 {
    font-size: 1.125rem;
    text-transform: capitalize;
    color: #fff;
    padding: 0 25px;
    border-top: 1px solid #363b4c;
    padding-top: 30px;
    margin-bottom: 15px;
}

.sidebar p {
    font-size: .875rem;
}

.sidebar.active .sidebar-nav li a {
    padding: 8px 5px;
}

.sidebar.active h5 {
    font-size: .75rem;
    padding: 25px 5px 5px 5px;
}

.sidebar.active .sidebar-nav li a span.nav-text {
    display: none;
}

.sidebar.active .sidebar-nav li a {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.sidebar.active .logo {
    padding: 15px 0;
}

.sidebar.active .sidebar-nav {
    margin: 18px 0;
}

.sidebar.active .sidebar-bottom {
    display: none;
}

.top-header {
    background: #697e95;
    padding: 0 8px;
}

    .top-header .form-control {
        border-radius: .25rem;
        background: #fff;
        height: calc(38px + 2px);
    }

.btn-menu {
    background: none;
    border: none;
    outline: none !important;
    cursor: pointer;
}

.header-form {
    margin: 0 -4px;
    padding: 0;
    list-style: none;
}

    .header-form li:nth-of-type(1) {
        width: 40%;
        float: left;
        padding: 0 4px;
    }

    .header-form li:nth-of-type(2) {
        width: 60%;
        float: left;
        padding: 0 4px;
    }

.header-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
}

    .header-nav li {
        display: inline-block;
        margin: 0 -2px;
        padding: 0;
    }

        .header-nav li:nth-of-type(1) {
            display: inline-block;
            margin: 0 20px;
            padding-top: 8px;
            float: left;
        }

        .header-nav li a {
            border-left: 1px solid #656c7a;
            display: block;
            padding: 16px 22px;
            margin: 0;
            color: #fff;
        }

            .header-nav li a:hover, .header-nav li a:focus {
                background: #656c7a;
                text-decoration: none;
            }

            .header-nav li a .fa {
                margin-right: 10px;
            }

.p-main {
    padding: 3.125rem;
}

.page-header {
    margin-bottom: 50px;
}

.page-title {
    font-size: 2.125rem;
    color: #445e7b;
    text-transform: uppercase;
    font-weight: 600;
}

.page-title-home {
    font-size: 2.125rem;
    color: #445e7b;
    font-weight: 600;
}

.tab-content {
    border: 1px solid #d8d9da;
    background: #fff;
    padding: 1.875rem;
    z-index: 2;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.header-nav li .dropdown-menu a {
    border-left: none;
    color: #242836;
    padding: 10px 20px;
}

    .header-nav li .dropdown-menu a:hover, .header-nav li .dropdown-menu a:focus {
        background: #f8f9fa;
    }

.header-nav li .dropdown-menu {
    top: 54px !important;
}

.animation {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes slideDownIn {
    0% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }

    0% {
        transform: translateY(-30px);
    }
}

.slideDownIn {
    -webkit-animation-name: slideDownIn;
    animation-name: slideDownIn;
}

.logo-mobile {
    display: none;
}

.baroverflow {
    position: relative;
    overflow: hidden;
    width: 180px;
    height: 180px;
    margin: 0 auto;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 180px; /* full circle! */
    border-radius: 50%;
    box-sizing: border-box;
    border: 12px solid #d1cede; /* half gray, */
    border-bottom-color: #2196f3; /* half azure */
    border-right-color: #2196f3;
}

.progress {
    height: 90px;
    background: none;
}

.progress-count {
    position: absolute;
    bottom: 20px;
    left: 0px;
    color: #242836;
    font-size: 3.5rem;
    font-weight: 600;
    width: 100%;
}

.est-remaning {
    color: #616c73;
    font-weight: 400;
    font-family: 'Barlow', sans-serif;
    font-size: 1.375rem;
    margin-top: 10px;
}

.super-sever-flex-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
}

    .super-sever-flex-box img {
        max-width: 105px;
        margin-right: 30px;
    }

.expires-right {
    position: absolute;
    font-family: 'Barlow', sans-serif;
    top: 0px;
    right: 0px;
    font-size: 1.125rem;
}

.expires-badge {
    color: #fff;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    background: #2196f3;
    padding: 5px 25px 5px 40px;
    margin-right: -30px;
    position: relative;
}

    .expires-badge:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-left-color: #fff;
        border-right: 0;
        margin-top: -20px;
        margin-right: -20px;
    }

.plans-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

    .plans-list li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: left;
        background: #f8f8f8;
        border-radius: 3px;
        font-weight: 400;
        color: #3f4759;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding: 15px;
    }

        .plans-list li:last-child {
            margin-bottom: 0px;
        }

        .plans-list li img {
            max-width: 30px;
            margin-right: 15px;
        }

        .plans-list li .btn {
            margin-top: 0;
            margin-left: auto;
        }

.js-responsive-table table, .fold-table {
    width: 100%;
    border: 1px solid #d2d3d4;
}

    .js-responsive-table th, .js-responsive-table td, .fold-table td, .fold-table th {
        padding: 12px;
    }

    .js-responsive-table tr, .fold-table tr {
        border-bottom: 1px solid #d2d3d4;
    }

    .js-responsive-table th, .fold-table th {
        background: #3f4759;
        padding: 16px;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
    }

.js-responsive-table .icon-accordion {
    display: none;
}

.js-responsive-table td h3 {
    margin: 0;
    padding: 0 0 5px 0;
    display: none;
    color: #616c73;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
}

.table-list-count {
    font-size: 18px;
    font-family: 'Barlow', sans-serif;
}

.count-arrows {
    background: #9fa3ac;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    font-size: .65rem;
    margin: 0px 0 10px 10px;
    text-align: center;
    line-height: 20px;
}

    .count-arrows:hover, .count-arrows:focus {
        background: #3f4759;
        color: #fff;
    }

table.fold-table > tbody > tr.view td:first-child, table.fold-table > tbody > tr.view th:first-child {
    position: relative;
}

table.fold-table > tbody > tr.view td {
    cursor: pointer;
}

    table.fold-table > tbody > tr.view td:first-child:before, table.fold-table > tbody > tr.view th:first-child:before {
        position: absolute;
        top: 50%;
        left: 20px;
        width: 9px;
        height: 16px;
        margin-top: -8px;
        font-family: 'FontAwesome';
        line-height: 16px;
        color: #3f4759;
        font-size: 1.25rem;
        content: "\f055";
        transition: all .3s ease;
    }

    table.fold-table > tbody > tr.view td:first-child {
        padding-left: 45px;
    }

table.fold-table > tbody > tr.view:hover {
    background: #f3f3f3;
}

table.fold-table > tbody > tr.view.open {
    background: #f3f3f3;
}

    table.fold-table > tbody > tr.view.open td:first-child:before, table.fold-table > tbody > tr.view.open th:first-child:before {
        content: "\f056";
    }

table.fold-table > tbody > tr.fold {
    display: none;
}

    table.fold-table > tbody > tr.fold.open {
        display: table-row;
        background: #f3f3f3;
    }

.kanit {
    font-family: 'Kanit', sans-serif;
}

.btn-yellow {
    background: #fdd835;
    border-color: #fdd835;
    color: #635205
}

    .btn-yellow:hover, .btn-yellow:focus {
        background: #eabf02;
        border-color: #eabf02;
        color: #635205
    }

.btn-pay {
    background: #1b1b1b;
    border-color: #1b1b1b;
    color: #fff !important;
}

    .btn-pay:hover, .btn-pay:focus {
        background: #3f4759;
        border-color: #3f4759;
        color: #fff !important;
    }

.link-btn {
    font-weight: 700;
    font-family: 'Barlow', sans-serif;
    color: #1e2930;
}

    .link-btn img {
        margin-right: 10px !important;
    }

    .link-btn span {
        border-bottom: 2px solid #cacbcd;
        position: relative;
    }

    .link-btn:hover {
        text-decoration: none;
        color: #2196f3;
    }

    .link-btn span::before {
        width: 100%;
        height: 100%;
        z-index: 3;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        box-sizing: border-box;
        -webkit-transform: scale(0);
        transition: 0.5s;
        border-bottom: 2px solid #2196f3;
        border-left: 0;
        -webkit-transform-origin: 0% 100%;
    }

    .link-btn span:hover::before {
        -webkit-transform: scale(1);
    }

.diverter {
    background: #d8d9da;
    width: 100%;
    height: 1px;
    margin-bottom: 60px;
    margin-top: -20px;
}

.renew-product-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

.renew-product-first {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.renew-product-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.renew-product-left {
    padding: 0 30px;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.renew-product-right {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

    .renew-product-right ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .renew-product-right ul li {
            border-bottom: 1px solid #d8d9da;
            padding: 10px 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

            .renew-product-right ul li:last-child {
                border-bottom: none;
            }

            .renew-product-right ul li span {
                color: #616c73;
                font-weight: 600;
                font-size: 1.5rem;
                -webkit-flex: 1;
                -ms-flex: 1;
                flex: 1;
            }

            .renew-product-right ul li label {
                -webkit-flex: 1;
                -ms-flex: 1;
                flex: 1;
                margin-bottom: 0px;
            }

.plan-current {
    color: #6a777e;
    font-weight: 400;
    font-size: .875rem;
    background: #eaeaeb;
    padding: 2px 14px;
    border-radius: 1rem;
    display: inline-block;
    text-transform: uppercase;
}

.renew-product-center {
    border-right: 1px solid #d8d9da;
    margin-right: 30px;
    padding-right: 30px;
}

.renew-product-address {
    color: #616c73;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1;
}

.featured-product-box {
    display: flex;
    border: 1px solid #d8d9da;
    border-radius: 5px;
    margin-bottom: 35px;
}

.featured-product-box-plan {
    flex: 3;
    padding: 5px;
    border-right: 1px solid #d8d9da;
}

.featured-product-box-price {
    flex: .75;
    padding: 30px;
    border-right: 1px solid #d8d9da;
}

.featured-product-box-term {
    flex: .75;
    padding: 30px;
    border-right: 1px solid #d8d9da;
}

.featured-product-box-signup {
    flex: 1.2;
    padding: 30px;
}

.featured-product-box h3 {
    font-size: 1.875rem;
    margin-bottom: 25px;
}

.featured-product-box p {
    margin-bottom: 25px;
}

.featured-product-box label {
    color: #6a777e;
    font-size: 1.375rem;
    font-weight: 300;
    margin-bottom: 20px;
}

.featured-product-box .btn {
    padding-top: 6px;
    padding-bottom: 6px;
}

.plan-details {
    display: none;
}

.plan-details-btn div.fa {
    width: 25px;
    height: 25px;
    float: left;
    border: 2px solid #cacbcd;
    border-radius: 50%;
    color: #cacbcd;
    line-height: 22px;
    font-size: 1.125rem;
    margin-right: 10px;
    text-align: center;
}

.plan-details {
    background: #f4f4f4;
    padding: 30px;
    margin-top: 15px;
}

.bullet-ul {
    list-style: none;
    margin: 0px 0;
    padding: 0;
}

    .bullet-ul li {
        padding: 2px 0 2px 25px;
        position: relative;
    }

        .bullet-ul li:before {
            content: "\f054";
            position: absolute;
            top: 3px;
            left: 0;
            width: .375rem;
            height: .625em;
            font-family: 'FontAwesome';
            color: #2196f3;
        }

.offered-by-code-title {
    background: #343A40;
    font-weight: 600;
    font-size: 1.375rem;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 15px;
    display: table;
    position: relative;
}

    .offered-by-code-title:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 0.90em solid transparent;
        border-left-color: #343A40;
        border-right: 0;
        margin-top: -0.90em;
        margin-right: -0.90em;
    }

.featured-title {
    background: #445E7B;
    font-weight: 600;
    font-size: 1.375rem;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 15px;
    display: table;
    position: relative;
}

    .featured-title:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 0.90em solid transparent;
        border-left-color: #445E7B;
        border-right: 0;
        margin-top: -0.90em;
        margin-right: -0.90em;
    }

.featured-product-box-span {
    font-size: 1.125rem;
    color: #1e2930;
}

.featured-product-box h4 {
    margin-bottom: 0;
}

.my-account-section {
    width: 100%;
    min-height: 100%;
    background-size: cover;
}

.my-account-container {
    width: 100%;
    padding: 0px 150px 150px 150px;
}

.login-box {
    display: flex;
}

.login-left {
    background: #fff;
    width: 56%;
    border: 1px solid #445e7b;
}

.login-right {
    background: #445e7b;
    flex: 1;
}

.a-link {
    text-decoration: underline;
    color: #1e2930;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
}

    .a-link:hover, .a-link:focus {
        color: #1f97f6;
    }

.my-or {
    text-align: center;
    position: relative;
    margin: 30px 0;
}

    .my-or:after {
        width: 100%;
        height: 1px;
        background: #d8d9da;
        position: absolute;
        left: 0px;
        top: 17px;
        content: "";
        z-index: 1;
    }

    .my-or span {
        margin: 0 auto;
        z-index: 2;
        height: 35px;
        line-height: 35px;
        background: #f1f3f4;
        border-radius: 35px;
        display: inline-block;
        font-size: .875rem;
        color: #616c73;
        font-weight: 400;
        padding: 0 10px;
        text-transform: uppercase;
        position: relative;
        text-align: center;
    }

.login-social {
    margin: 0 -15px;
    padding: 0;
    list-style: none;
}

    .login-social li {
        width: 33.33%;
        float: left;
        padding: 0 15px;
    }

        .login-social li a {
            color: #fff;
            font-family: 'Barlow', sans-serif;
            font-weight: 400;
            text-align: center;
            padding: 10px 20px;
            border-radius: 30px;
            display: block;
        }

            .login-social li a .fa {
                margin-right: 10px;
            }

            .login-social li a:hover, .login-social li a:focus {
                opacity: .75;
                text-decoration: none;
            }

            .login-social li a.facebook {
                background: #3b5a9a;
            }

            .login-social li a.twitter {
                background: #55acee;
            }

            .login-social li a.linkedin {
                background: #2876b4;
            }

.login-left-content {
    margin: 0 auto;
    padding: 100px;
}

.login-right {
    color: #fff;
    padding: 90px;
}

    .login-right h3 {
        font-size: 1.875rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 35px;
    }

        .login-right h3 span {
            font-weight: 300;
        }

.login-right-ul {
    margin: 0;
    padding: 20px 0;
    list-style: none;
}

    .login-right-ul li {
        font-family: 'Barlow', sans-serif;
        font-size: 18px;
        padding: 10px 0 10px 40px;
        background: url(../../images/checkbox-icon.png) 0px 10px no-repeat;
    }

.login-diverder {
    width: 100%;
    height: 1px;
    background: #fff;
    margin: 30px 0;
    position: relative;
}

    .login-diverder:after {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 0px;
        background: #fff;
        top: -5px;
        content: "";
        position: absolute;
    }

.quickpay-box {
    display: flex;
    margin-bottom: 25px;
    align-items: center;
}

    .quickpay-box img {
        margin-left: 30px;
    }

.my-account-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.my-account-language {
    color: #445e7b;
}

    .my-account-language:hover, .my-account-language:focus {
        color: #445e7b;
        text-decoration: none;
        opacity: .75;
    }

    .my-account-language .fa {
        margin-right: 10px;
    }

.my-account-md-container {
    max-width: 900px;
    margin: 0 auto;
}

.my-account-md-content {
    padding: 120px;
    background: #fff;
}

    .my-account-md-content p {
        font-size: 1.125rem;
    }

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background-color: #80c765;
}

input:focus + .slider {
    box-shadow: 0 0 1px #80c765;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

    .slider.round:before {
        border-radius: 50%;
    }

.noti-switch {
    list-style: none;
    margin: 0 0 0 30px;
    padding: 0;
    display: table;
}

.account-noti-box {
    background: #f4f4f4;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.noti-switch li {
    display: inline-block;
    border-left: 1px solid #d2d3d4;
    padding: 0 30px;
}

    .noti-switch li:last-child {
        padding-right: 0px;
    }

.account-noti-left {
    flex: 1;
}

.notifi-icon {
    margin-right: 10px
}

.account-noti-box .switch {
    margin-top: -15px;
}

.account-noti-box h6 {
    margin-bottom: 12px;
    font-size: 1.25rem;
}

.my-account-md-container h6 {
    font-size: 1.125rem;
}

.input-details {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: .875rem;
    color: #afafaf;
    font-style: italic;
}

.phone-no {
    font-weight: 600;
    color: #1e2930;
    font-size: 1.875rem;
    margin: 10px 0 25px 0;
}

    .phone-no img {
        margin-right: 20px;
    }

.contact-right p {
    line-height: 1.9;
}

    .contact-right p strong {
        color: #1e2930;
        font-family: 'Kanit', sans-serif;
        font-size: 1.125rem;
        font-weight: 500;
    }

.social-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

    .social-ul li {
        display: inline-block;
    }

        .social-ul li a {
            width: 40px;
            height: 40px;
            display: block;
            border-radius: 50%;
            color: #fff;
            text-align: center;
        }

            .social-ul li a:hover, .social-ul li a:focus {
                text-decoration: none;
                opacity: .8;
            }

            .social-ul li a svg {
                font-size: 1.375rem;
                display: block;
                line-height: 40px;
            }

a.social-ul-fb {
    background: #3b5998;
}

a.social-ul-tw {
    background: #55acee;
}

a.social-ul-in {
    background: #007ab9;
}

a.social-ul-pn {
    background: #cb2027;
}

a.social-ul-dr {
    background: #f26496;
}

.payment-section {
    display: flex;
    min-height: calc(100% - 56px);
    justify-content: flex-start;
}

.right-sidebar {
    background: #e9e9e9;
    flex: 0 0 400px;
    width: 400px;
    padding: 2.875rem;
}

.left-sidebar {
    flex: 1 1 auto;
}

.bl-blue {
    border-left: 4px solid #2196f3;
}

.autopay-flex-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    justify-content: space-between;
}

.autopay-flex-box-left {
    display: flex;
}

    .autopay-flex-box-left img {
        margin-right: 30px;
        width: 75px;
        height: 74px;
    }

.gray-box {
    box-shadow: 0.105px 0.995px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background: #f4f4f4;
    padding: 1.875rem;
    margin-bottom: 30px;
}

.accordian-btn {
    color: #1e2930;
    font-size: 1.125rem;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    display: block;
    border-top: 1px solid #d3d4d7;
    padding: 18px 0;
}

    .accordian-btn svg {
        float: right;
    }

    .accordian-btn:hover, .accordian-btn:focus {
        color: #2196f3;
        text-decoration: none;
    }

.date-time {
    position: relative;
}

    .date-time:after {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 14px;
        font-size: 1.125rem;
        color: #c3c4c5;
        right: 25px;
        content: "\f073";
        font-family: 'FontAwesome';
    }

.accordian-card {
    border: 1px solid #d3d4d7;
    border-radius: 5px;
    padding: 15px;
}

.accordian-card-select {
    border-bottom: 0.5px solid #d3d4d7;
    padding: 10px;
    cursor: pointer;
}

.accordian-card-select:hover {
    background-color: #f5f5f5;
}

.accordian-card-selected {
    padding: 10px;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    color: #1e2930 !important;
}

.accordian-card a {
    color: #1e2930;
    font-weight: 400;
    font-size: 1.125rem;
}

.accordian-card .exp-date {
    font-style: italic;
    color: #616c73;
    font-size: 1rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    margin: 6px 10px 0 0;
}

.accordian-card svg {
    color: #6a777e;
    margin-top: 6px;
    font-size: 1.5rem !important;
}

.open-accordian {
    border: 1px solid #d3d4d7;
    padding: 15px;
    border-radius: 5px;
}

.payment-link {
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    color: #5b666c;
    text-transform: uppercase;
}

.payment-link img {
    margin-right: 12px;
}

.payment-link span {
    border-bottom: 2px solid #5b666c;
}

.payment-link:hover, .payment-link:focus {
    color: #2196f3;
    text-decoration: none;
}

.payment-link:hover.payment-link span {
    border-bottom: 2px solid #2196f3;
}

.pay-card {
    display: flex;
}

.pay-card img {
    width: 78px;
    height: 70px;
}

.pay-card ul {
    margin: 0;
    padding: 0 0 0 30px;
    list-style: none;
    width: 100%;
}

.pay-card ul li {
    display: flex;
    padding: 7px 0;
}

.pay-card ul li span {
    font-size: 1.125rem;
    color: #616c73;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    flex: 1;
}

.pay-card ul li strong {
    font-size: 1.375rem;
    font-family: 'Kanit', sans-serif;
    flex: 1;
    font-weight: 600;
}

.other-amount-box {
}

.bl-br {
    border-left: 4px solid #e53935;
}

.pay-echeck {
    display: flex;
}

    .pay-echeck img {
        width: 79px;
        height: 80px;
        margin-right: 25px;
    }

.inputGroup {
    background-color: #fff;
    display: inline-block;
    margin: 14px 25px 30px 0;
    position: relative;
}

.inputGroup label {
    padding: 20px 70px 20px 100px;
    border-radius: 5px;
    border: 1px solid #d8d9da;
    width: 100%;
    text-align: center;
    display: block;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    font-size: 1.125rem;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
}

    .inputGroup label:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #81c765;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        z-index: -1;
    }

    .inputGroup label:after {
        width: 32px;
        height: 32px;
        font-family: 'FontAwesome';
        border: 3px solid #71787e;
        background-color: #fff;
        color: #fff;
        content: "\f00c";
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
    }

.inputGroup input:checked ~ label {
    color: #fff;
}

        .inputGroup input:checked ~ label:before {
            transform: translate(-50%, -50%) scale3d(56, 56, 1);
            opacity: 1;
        }

        .inputGroup input:checked ~ label:after {
            background-color: #fff;
            color: #81c765;
            border-color: #fff;
        }

    .inputGroup input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
    }

.credit-card-icon {
    width: 60px;
    display: block;
    margin: 0 auto 8px auto;
    height: 60px;
    background: url(../../images/sfed/credit-card.png) no-repeat;
    background-position: top;
}

.bank-icon {
    width: 60px;
    display: block;
    margin: 0 auto 8px auto;
    height: 60px;
    background: url(../../images/sfed/bank-icon.png) no-repeat;
    background-position: top;
}

.inputGroup input:checked ~ label .bank-icon, .inputGroup input:checked ~ label .credit-card-icon {
    background-position: bottom;
}

.routing-pic {
    border-radius: 5px;
    box-shadow: 0.314px 2.984px 8.28px 3.72px rgba(0, 0, 0, 0.15);
    height: 10vh;
}

.credit-card-input {
    position: relative;
}

    .credit-card-input:before {
        font-family: 'FontAwesome';
        content: "\f09d";
        position: absolute;
        font-size: 1.5rem;
        top: 41px;
        left: 20px;
        color: #c8cace;
    }

    .credit-card-input .form-control {
        padding-left: 55px;
    }

.bl-green {
    border-left: 4px solid #80c765;
}

.quick-pay-box {
    max-width: 800px;
}

.card-title-lg {
    font-size: 1.625rem;
    font-weight: 600;
}

.pay-with-diffrent {
    box-shadow: 0.314px 2.984px 8.28px 3.72px rgba(0, 0, 0, 0.15);
    background: #fff;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px 8px 25px;
}

    .pay-with-diffrent .form-control {
        width: 100px;
        height: calc(32px + 2px);
    }

    .pay-with-diffrent label {
        margin-bottom: 0;
        color: #696969;
        text-transform: uppercase;
    }

.has-error, .has-error label {
    color: #e53935;
}

    .has-error .form-control {
        border-color: #e53935;
    }

        .has-error .form-control:focus {
            box-shadow: 0 0 0 .2rem rgba(229,57,53,.25)
        }

.gray-table {
    width: 100%;
    display: flex;
    border: 1px solid #d8d9da;
    margin-bottom: 30px;
    border-radius: .25rem;
}

.gray-table-dt {
    flex: 1 1 auto;
    margin-bottom: 0px;
}

.gray-table-dt-value {
    margin-bottom: 0;
    padding: 14px 30px;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    background: #fff;
}

.gray-table-dt-label {
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    padding: 14px 30px;
    background: #6a777f;
}

.contact-renew {
    display: flex;
    align-items: center;
}

    .contact-renew img {
        margin-right: 30px;
    }

.phone-no-renew {
    color: #1e2930;
    font-weight: 600;
    font-size: 2.75rem;
    padding: 0 40px;
}

.autopay-link {
    color: #566168;
    text-transform: uppercase;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
}

    .autopay-link img {
        margin-right: 10px;
        margin-top: -4px;
    }

.recomended-box {
    display: flex;
    align-items: center;
}

.recomender-btn {
    box-shadow: 0.314px 2.984px 8.28px 3.72px rgba(0, 0, 0, 0.15);
    background: #fff;
    border-radius: 2rem;
    display: flex;
    font-weight: 400;
    color: #6a777e;
    padding: 15px 8px 15px 25px;
    font-family: 'Barlow', sans-serif;
}

    .recomender-btn:hover, .recomender-btn:focus {
        color: #1e2930;
        text-decoration: none;
        box-shadow: 0.314px 2.984px 8.28px 3.72px rgba(0, 0, 0, 0.25);
    }

.or-txt {
    color: #afafaf;
    text-transform: uppercase;
    padding: 0 20px;
}

.recomended-first {
    width: 180px;
}

.sp-tag {
    display: inline-block;
}

.modal-body {
    padding: 40px;
}

.modal-header {
    padding: 1rem 1.5rem;
}

.btn-login {
    width: 100%;
}

.login-social li a svg {
    margin-right: 10px;
}

.sidebar-nav li a svg {
    margin: 0 8px;
}

.sidebar-nav-header li a svg {
    margin: 0 8px;
}

.header-nav li a svg {
    margin-right: 10px;
}

.pull-right {
    float: right;
}

.bg-danger {
    border-color: #e53935 !important;
    color: white !important;
    border-width: 2px;
}

body {
    position: relative;
}

.full-screen-overlay {
    background: rgba(255,255,255,.85);
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.p-main {
    position: relative;
}

.content-screen-overlay {
    background: rgba(255,255,255,.85);
    position: absolute;
    z-index: 999;
    height: calc(100% - 56px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


.credit-card-checkbox-outer {
    border: 1px solid #d3d4d7;
    background: #fff;
    border-radius: 5px;
}

.checked {
    border: 1px solid #bfbfbf;
    background: #f5f5f5;
}

.credit-card-checkbox-outer .custom-control-label {
    width: 100%;
    padding: 15px 20px;
}

    .credit-card-checkbox-outer .custom-control-label::before {
        top: 1.20rem;
        left: -.5rem;
    }

    .credit-card-checkbox-outer .custom-control-label::after {
        top: 1.2rem;
        left: -.5rem;
    }

.m-top-32 {
    margin-top: 32px;
}

.credit-card-paybox {
    padding-right: 25px;
    padding-bottom: 10px;
}

.checked .credit-card-paybox .form-control {
    background: #fff;
    border: 1px solid #d3d4d7;
}

.modal-header {
    background-color: #445e7b !important;
}

.single-value-input {
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
}

.password {
    display: block;
    position: relative;
    width: 100%;
}

.password__input {
    display: block;
}

.password__show {
    cursor: pointer;
    position: absolute;
    top: 33px;
    height: 52px;
    right: 1px;
    background: #a3a3a3;
    color: white;
    width: 15%;
    padding: 15px 0 0 0;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    text-align: center;
    font-size: .9em;
}

.password__strength {
    position: absolute;
    width: 0%;
    height: 6px;
    bottom: -18px;
    left: 20px;
    right: 20px;
    background: transparent;
    transition: all 300ms ease-in-out;
    max-width: 81%;
    cursor: pointer;
}

.password__strength[data-score="null"] {
    width: 0;
    background: red;
}

.password__strength[data-score="0"] {
    width: 5%;
    background: #F44336;
}

.password__strength[data-score="1"] {
    width: 25%;
    background: #F44336;
}

.password__strength[data-score="2"] {
    width: 50%;
    background: #FFEB3B;
}

.password__strength[data-score="3"] {
    width: 75%;
    background: #4CAF50;
}

.password__strength[data-score="4"] {
    width: 100%;
    background: #4CAF50;
}

.btn-primary {
    color: #fff;
    background-color: #445e7b !important;
    border-color: #445e7b !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #697e95 !important;
    border-color: #697e95 !important;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #445e7b !important;
    border-color: #445e7b !important;
}

.logo-my-account {
    height:80px !important;
}

.logo-my-account img {
    height: 64px !important;
}

.my-account-md-content {
    border: 1px solid #445e7b;
}


/**** Styles taken from enrollment app ****/

.wizard-ul {
    margin: 0;
    padding: 0;
    left: 16%;
    list-style: none;
    position: relative;
}

    .wizard-ul p {
        padding-bottom: 16px;
        white-space: nowrap;
        color: #445E7B;
    }

    .wizard-ul::after {
        background: #445E7B;
        width: 35%;
        height: 2px;
        position: absolute;
        left: 17%;
        top: 42px;
        content: "";
    }

    .wizard-ul li {
        float: left;
        width: 35%;
        text-align: center;
        position: relative;
    }


a:focus, a:hover, a:visited {
    text-decoration: underline;
}

.round-tab a span {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: 6px;
    border: 2px solid #445E7B;
    background: #445E7B;
    z-index: 2;
    margin-left: -11px;
}

.unselected-tab a span {
    background: #fff;
    border: 2px solid #445E7B;
}

.unselected-tab p span {
    color: #fff;
}






.text-center-th th {
    text-align: center !important;
}




.top-view-products {
    border-bottom: 1px solid #e4e4e4;
    padding: 10px 0;
}

    .top-view-products h5 {
        color: #445E7B;
        font-weight: 700;
        margin-top: 15px;
        font-size: 15px;
    }

    .top-view-products p a {
        margin-left: 20px;
    }

.custom-products {
    /*margin: 35px 0;*/
    font-weight: 700;
}

    .custom-products span {
        color: #445E7B;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
    }

.featured-products {
    padding: 30px;
    position: relative;
    border-radius: 20px;
    border: 6px solid #445E7B;
    margin: 10px 0;
    box-shadow: 0px 2px 8px 3px rgba(0,0,0,.45);
}

    .featured-products h2 {
        font-weight: 700;
        font-size: 36px;
    }

    .featured-products h4 {
        font-style: italic;
    }

    .featured-products ul {
        margin: 0px 15px;
        padding: 0;
    }

.featured-products-title {
    background: #445E7B;
    position: absolute;
    top: -15px;
    left: 30px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    border-radius: 5px;
    padding: 3px 8px;
}

.featured-products table {
    margin: 30px 0 0 0;
}

    .featured-products table h3 {
        font-weight: 700;
        margin: 10px 0;
        line-height: .8;
        text-align: center;
    }

    .featured-products table small {
        font-size: 13px;
        color: #333;
    }

.columns {
    display: inline-block;
    margin-left: -3px;
    width: 25%;
    padding: 30px 15px;
}

.price {
    list-style-type: none;
    border: 1px solid #eee;
    box-shadow: -3px 5px 6px 3px rgba(0,0,0,.35);
    -webkit-box-shadow: -3px 5px 6px 3px rgba(0,0,0,.35);
    -moz-box-shadow: -3px 5px 6px 3px rgba(0,0,0,.35);
    -o-box-shadow: -3px 5px 6px 3px rgba(0,0,0,.35);
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 10px;
    overflow: hidden;
}

    .price:hover {
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
    }

    .price .header {
        background-color: #445E7B;
        color: #fff;
        font-size: 20px;
        line-height: 1.2;
        padding: 20px;
        font-weight: 700;
        min-height: 90px;
    }

    .price li {
        border-bottom: 1px solid #e4e4e4;
        text-align: center;
    }

.border-none {
    border: none !important;
    text-align: left !important;
    padding: 20px;
    padding-bottom: 13px !important;
}

.min-h-price {
    padding: 20px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 0px;
    padding-bottom: 0px;
    min-height: 200px;
}

.border-none i {
    color: #445E7B;
    margin-right: 12px;
}

.btn-view {
    padding: 20px;
}

.price .grey {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    color: #445E7B;
}

    .price .grey span {
        font-size: 12px;
        font-weight: 400;
        color: #757777;
    }

    .price .grey p {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 400;
        line-height: 1.5;
        color: #757777;
    }

.price .btn-primary {
    margin-bottom: 15px;
    margin-top: 15px;
    background: #445E7B;
    border: none;
}

footer h3 {
    font-size: 24px;
    margin-bottom: 25px;
    color: #fff;
    font-weight: 300;
}

input[type=text] + small {
    position: relative;
    left: 30%;
    font-style: italic;
    color: darkgrey;
}

.clarification {
    text-align: right;
    padding-right: 5px;
    font-style: italic;
    color: darkgrey;
}

/**** Styles taken from enrollment app 2 ****/

.page-sub-title-green {
    background: #445E7B;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    padding: 10px 10px 10px 15px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.edit-information-box {
    padding: 10px 0;
}

.info-section {
    padding: 0 15px;
    padding-bottom: 5px;
    color: #343434;
}

    .info-section strong {
        color: #000;
    }

.a-underline {
    text-decoration: underline;
}

    .a-underline:hover, .a-underline:focus {
        text-decoration: none;
    }

.checkbox, .radio {
    position: relative;
    display: block;
    margin-top: 15px;
    margin-bottom: 25px;
}

.banner a {
    color: #fff;
    border-color: #3f4759;
}

.img-fluid-container img {
    max-width: 100%;
    height: auto
}

.expires-right-top {
    top: -18px
}

.expires-right-bottom {
    top: 50px
}

.card-higher {
    height: 160px
}

.location-status-label {
    width: -webkit-fill-available;
    text-align: center;
    min-width: 165px;
}

.location-activating {
    background: #007bff;
    color: #fff;
}

.location-active {
    background: #28a745;
    color: #fff;
}

.location-pending-disconnect {
    background: #ffc107;
    color: #000000;
}

.location-disconnected {
    background: #dc3545;
    color: #fff;
}

.location-switched-away {
    background: #6c757d;
    color: #fff;
}

.location-moved-out {
    background: #6c757d;
    color: #fff;
}

.location-none {
    background: #fff;
    color: #000000;
}

.location-no-service-activated {
    background: #6c757d;
    color: #fff;
}

.location-problem {
    background: #dc3545;
    color: #fff;
}

.tab-image {
    position: relative;
    text-align: left;
    color: white;
}

.image-text {
    display: table-cell;
    position: relative;
    bottom: 18px;
    left: 14px;
    width: 45px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    color: black;
    font-size: 8px;
    font-weight: bold;
}

.card-flex {
    display: flex;
    align-items: flex-start;
}

.card-flex-leftlabel {
    margin-left: 10px;
    width: 40%;
}

.card-flex-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #445e7b;
}